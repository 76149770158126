<template>
  <section id="home-offer-tiles" :class="cssClass">
    <div class="wrapper">
        <SectionHeader v-if="content.header" :header="content.header" :sub="content.subHeader"></SectionHeader>
    </div>
    <div class="items offer-items">
      <div class="item" :key="key" v-for="(item, key) in offerTilesContent">

        <router-link :to="'/demo/' + item.path">
        <div class="hover-content">
          <span class="title">{{ item.name }}</span>
          <p>{{ item.description }}</p>
        </div>
        <div class="name">
            <span>{{ item.name }}</span>
        </div>
        <div class="cover"></div>
        <img
        loading="lazy"
          :src="getActiveImg(item.img).url"
          class="bg"
          :alt="getActiveImg(item.img).alt"
        />
      </router-link>
      </div>


    </div>
  </section>
</template>
<script>
import _ from 'lodash';

import { getRandom, setActiveOfferImages } from "@/mixins/mixins.js";

import SectionHeader from "../template-parts/SectionHeader.vue";

export default {
  name: "OfferView02",
  components: {
    SectionHeader,
  },
  mixins: [getRandom, setActiveOfferImages],
  props: ["content", "cssClass"],
  computed: {
    pagesList(){
        return this.$store.state.pageContent.pages;
    },
    offerTilesContent(){
        return _.filter(this.pagesList, 
            (o) => { return o.template === "offer" && o.path !== this.path && o.path !== 'offer'; });
    }
  }
};
</script>
<style scoped></style>
