import { createApp } from 'vue'
import App from './App.vue'
import store from "./store/store";
import router from "./router/router";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


//main
import './assets/style/main.css';


const vuetify = createVuetify({
    components,
    directives,
  })

createApp(App).use(store).use(vuetify).use(router).mount('#app');
