<template>
  <div class="layoutSwitcher">
    <ul>
      <li>
        <button class="toolbarItem" @click="changeLayout()">
          <div class="icon">
            <img src="@/assets/images/app/magic-tool.svg" alt="" />
          </div>
          <span>{{ this.$store.getters.currentLang.toolbar.changeSectionLayout }}</span>
        </button>
      </li>
      <li>
        <button class="toolbarItem" @click="openPopup()">
          <div class="icon">
            <img src="@/assets/images/app/edit-content.svg" alt="" />
          </div>
          <span>{{ this.$store.getters.currentLang.toolbar.editContent }}</span>
        </button>
      </li>
      <li v-if="index !== 0 && component !== 'startView' && component !== 'footer'">
        <button class="toolbarItem" @click="moveUp()">
          <div class="icon">
            <img src="@/assets/images/app/up-arrow.svg" alt="" />
          </div>
          <span>{{ this.$store.getters.currentLang.toolbar.moveUp }}</span>
        </button>
      </li>
      <li v-if="index !== $store.state.homepageLayout.sections.length-1 && component !== 'startView' && component !== 'footer'">
        <button class="toolbarItem" @click="moveDown()">
          <div class="icon">
            <img src="@/assets/images/app/down-arrow.svg" alt="" />
          </div>
          <span>{{ this.$store.getters.currentLang.toolbar.moveDown }}</span>
        </button>
      </li>
      
    </ul>
  </div>
</template>
<script>
export default {
  name: "SectionLayoutSwitcher",
  props: ["component", "layout", "index"],
  methods: {
    changeLayout() {
      this.$store.commit("changeSectionLayout", {
        component: this.component,
        layout: this.layout,
        index: this.index,
      });
    },
    openPopup(){
      return this.$store.commit("openPopup", true);
    },
    moveUp(){
      this.$store.commit("moveSectionUp", {
        index: this.index,
      });
    },
    moveDown(){
      this.$store.commit("moveSectionDown", {
        index: this.index,
      });
    }
  },
};
</script>
<style scoped></style>
