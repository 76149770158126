<template>
  <div>
    <PageHeader
      :path="path"
      :title="pageContent.name"
      :subTitle="pageContent.description"
      :pageThumbnail="pageContent.thumbnail"
    ></PageHeader>
    <blogView :content="pageContent"
      :cssClass="blogSectionLayout.cssClass"
      ></blogView>
  </div>
</template>
<script>
import _ from "lodash";
import PageHeader from "../layouts/page-header/PageHeader.vue";
import blogView from "../layouts/blog/blogView01.vue";

import { setActiveOfferImages } from "@/mixins/mixins.js";



export default {
  name: "ContactPage",
  props: ["layout", "content", "path"],
  data() {
    return {};
  },
  mixins: [setActiveOfferImages],
  computed: {
    pageContent() {
      return _.filter(this.content.pages, { path: this.path })[0];
    },
    blogSectionLayout(){
        return _.filter(this.$store.state.homepageLayout.sections, { type: "blog" })[0];
    },
  },
  components: {
    PageHeader, blogView
  },
};
</script>
<style scoped>
pre {
  line-height: 1.5;
}
</style>
