<template>
 <div class="section-container header">
    <component :is="layout.component" :cssClass="layout.cssClass" :content="content"
    :startViewLightLayout="startViewLightLayout"
    ></component>
 </div>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import HeaderView01 from './HeaderView01.vue'

export default{
name: 'HederMain',
components: {
    HeaderView01
},
props: ["layout", "content"],
computed: {
    startViewLightLayout(){
        if(
            this.$store.state.homepageLayout.startView.cssClass === "layout04" ||
            this.$store.state.homepageLayout.startView.cssClass === "layout05"
        ){
            return true;
        } else{
            return false;
        }
    }
},
mounted(){
    $(window).on("scroll", function () {
            var scrolltop = $(this).scrollTop();
            if (scrolltop >= 1) {
                $("#header").addClass("scroll");
                        
                if (screen.availWidth > 768) {
                $("#header .top-bar").slideUp("300");
                }
                
            } else if (scrolltop <= 1) {
                $("#header").removeClass("scroll");

                        
                if (screen.availWidth > 768) {
                $("#header .top-bar").slideDown("300");
                }
                
            }
        });
  }

}
</script>
<style scoped>
</style>