<template>
   <div>
    <section id="contact-form" :class="cssClass">
        <div class="wrapper">
            <SectionHeader
            :header="content.header"
            :sub="content.subHeader"
            ></SectionHeader>
            <div class="flexbox">
                <div class="form-side global-content-config">
                    <h3>{{content.form.header}}</h3>
                    <form action="#">
                        <input type="text" :placeholder="content.form.name" required>
                        <div class="flex-fields">
                            <input type="email" :placeholder="content.form.email" required>
                            <input type="tel" :placeholder="content.form.tel" required>
                        </div>
                        <textarea name="#" id="" :placeholder="content.form.message"></textarea>
                        <span class="wpcf7-list-item"><label><input type="checkbox" name="acceptance" value="1" aria-invalid="false"><span class="wpcf7-list-item-label">{{content.form.allowance}}</span></label></span>
                        <div class="btn">
                            <input type="submit" :value="content.form.submit">
                        </div>
                    </form>
                </div>
                <div class="data-side global-content-config">
                    <h3>{{content.contactDataHeader}}</h3>
                    <p><b>{{ this.$store.state.pageContent.global.companyName }}</b></p>
                    <p>NIP: 123 456 789<br/>
                        REGON: 123 456 789
                    </p>
                    <ul class="contact-data">   
                        <li class="tel" v-if="this.$store.state.pageContent.global.tel"><a href="'tel'+this.$store.state.pageContent.global.tel">{{ this.$store.state.pageContent.global.tel }}</a></li>
                        <li class="email" v-if="this.$store.state.pageContent.global.email"><a :href="'mailto:'+this.$store.state.pageContent.global.email">{{ this.$store.state.pageContent.global.email }}</a></li>
                        <li class="location" v-if="this.$store.state.pageContent.global.address">{{ this.$store.state.pageContent.global.address }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d160257.5402202905!2d16.826971178047813!3d51.1264641221871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe9c2d4b58abf%3A0xb70956aec205e0f5!2zV3JvY8WCYXc!5e0!3m2!1spl!2spl!4v1715691661203!5m2!1spl!2spl" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

   </div>
  </template>
  <script>
  import SectionHeader from "../template-parts/SectionHeader.vue";
  import { getImgUrl } from "@/mixins/mixins.js";
  
  export default {
    name: "BenefitsView01",
    components: {
      SectionHeader,
    },
    mixins: [getImgUrl],
    props: ["content", "cssClass"],
  };
  </script>
  <style scoped></style>
  