<template>
  <section id="start-view" :class="cssClass">
    <div class="oneoftwo">
      <div class="content-wrap">
        <div class="content">
          <h1>{{ content.header }}</h1>
          <p>{{ content.subHeader }}</p>
          <div class="btn">
            <router-link to="/demo/offer">
              <button class="global v2">{{ content.btn }}</button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="bg-logo">
            <span>{{ this.$store.state.pageContent.global.companyName }}</span>
      </div>
    </div>
    <div class="oneoftwo">
      <div class="slick-slider">
        <div class="item" :key="key" v-for="(item, key) in slidersToShow">
            <img :src="item.url" :alt="item.alt" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import { getImgUrl, getRandom } from "@/mixins/mixins.js";
import "@/assets/js/slick.min.js";


export default {
  name: "StartView05",
  props: ["content", "cssClass"],
  mixins: [getImgUrl, getRandom],
  computed: {
    slidersToShow(){
      return this.content.images;
    }
  },
  updated(){
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);
  },
  mounted() {
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);

    $("section#start-view .slick-slider").slick({
        slidesToShow: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinity: true,
        lazyLoad: "ondemand",
      });
  }
};
</script>
<style scoped></style>
