<template>
  <div>
    <PageHeader
      :path="path"
      :title="pageContent.name"
      :subTitle="pageContent.description"
      :pageThumbnail="pageContent.thumbnail"
    ></PageHeader>
    <section v-if="pageContent.content" id="offer-content-page">
      <div
        class="wrapper global-content-config"
        v-html="pageContent.content"
      ></div>
    </section>
    <section id="about-team">
      <SectionHeader :header="pageContent.team.header"></SectionHeader>
      <div class="wrapper">
        <div class="people">
          <div class="item">
            <div class="box">
              <div class="desc">
                <div class="in">
                  <span class="name">Jan Kowalski</span>
                  <p>Chief executive officer</p>
                </div>
              </div>
              <img src="@/assets/images/person1.jpg" alt="" class="bg" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <div class="desc">
                <div class="in">
                  <span class="name">Anna Ładna</span>
                  <p>Chief technology officer</p>
                </div>
              </div>
              <img src="@/assets/images/person2.jpg" alt="" class="bg" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <div class="desc">
                <div class="in">
                  <span class="name">Mariusz Nowak</span>
                  <p>Account manager</p>
                </div>
              </div>
              <img src="@/assets/images/person3.jpg" alt="" class="bg" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <div class="desc">
                <div class="in">
                  <span class="name">Jacek Mądry</span>
                  <p>Account manager</p>
                </div>
              </div>
              <img src="@/assets/images/person4.jpg" alt="" class="bg" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="our-clients">
      <div class="wrapper">
        <div class="sub-header">
          <span class="header">{{pageContent.clients.header}}</span>
        </div>
        <div class="slick-slider">
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo1.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo2.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo3.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo4.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo5.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo6.svg" class="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import "@/assets/js/slick.min.js";

import PageHeader from "../layouts/page-header/PageHeader.vue";
import SectionHeader from "../layouts/template-parts/SectionHeader.vue";

export default {
  name: "ContactPage",
  props: ["layout", "content", "path"],
  data() {
    return {
      contactLayout: {
        component: "ContactView01",
        cssClass: "layout01",
      },
    };
  },
  computed: {
    pageContent() {
      return _.filter(this.content.pages, { path: this.path })[0];
    },
  },
  components: {
    PageHeader,
    SectionHeader,
  },
};
</script>
<style scoped>
pre {
  line-height: 1.5;
}
</style>
