<template>
  <div class="step">
    <p class="field-header mb">{{this.$store.getters.currentLang.generatorForm.step2.title}}</p>
    <v-alert
        v-if="selectedServices.length === 6"
        style="margin: 6px 0px;"
        :text="this.$store.getters.currentLang.generatorForm.step2.limitInfo"
        type="info"
        variant="tonal"
      ></v-alert>
    <v-chip-group
        v-model="selectedServices"
        column
        multiple
        @update:modelValue="emmitSelectedServices()"
      >
        <v-chip
          
          v-for="(item, key) in services"
          :key="key"
          filter
          size="large"
          color="primary"
          :disabled="selectedServices.length === 6 && !checkIfActive(key)"
        >
          {{ item }}
        </v-chip>
      </v-chip-group>

      <v-text-field
        style="margin-top: 12px;"
        append-inner-icon="mdi-plus"
        :label="this.$store.getters.currentLang.generatorForm.step2.textLabel"
        variant="outlined"
        hide-details
        single-line
        v-model="customService"
        @click:append-inner="addCustomServices"
        @keyup.enter="addCustomServices"
        :disabled="selectedServices.length === 6"
      ></v-text-field>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: "FormStep2",
  props: ["availableServices"],
  customService: null,
  data(){
    return {
      services: this.availableServices,
      selectedServices: [],
      customService: null
    }
  },
  computed: {
    selectedServicesNames(){
      let arr = [];
      this.selectedServices.forEach(element => {
        arr.push(this.services[element]);
      });
      return arr;
    }
  },
  methods: {
    emmitSelectedServices(){
      this.$emit("setServices", this.selectedServicesNames);
    },
    addCustomServices(){
      if(this.customService){
        this.services.push(this.customService);
        this.customService = "";
        this.selectedServices.push(this.services.length-1);
        this.emmitSelectedServices();
      }
    },
    checkIfActive(key){
      let index = _.findIndex(this.selectedServices, function(o) { return o === key; });
      if(index >= 0){
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped></style>
