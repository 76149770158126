<template>
    <section id="blog-archive" :class="cssClass">
        <div class="wrapper">
        <SectionHeader v-if="content.header" :header="content.header" :sub="content.subHeader"></SectionHeader>

            <div class="posts">
            <div class="item blog-single-post" v-for="(item, key) in postsList" :key="key">
                <div class="thumb">
                <img  @click="openPopup()" :src="item.thumbnail[0].url" :alt="item.thumbnail[0].alt" />
                </div>
                <div class="content">
                <span class="title" @click="openPopup()">{{ item.title }}</span>
                <p>{{ item.excerpt }}</p>
                </div>
            </div>
            </div>
        </div>
        <pre>{{ pageObj }}</pre>
        </section>
</template>
<script>
import _ from 'lodash';
import { setActiveOfferImages } from "@/mixins/mixins.js";
import SectionHeader from "../template-parts/SectionHeader.vue";


export default{
name: 'BlogView01',
props: ["content", "cssClass"],
mixins: [setActiveOfferImages],
components: {
    SectionHeader
},
methods: {
    openPopup(){
        return this.$store.commit("openPopup", true);

    }
},
computed: {
    pagesList(){
        return this.$store.state.pageContent.pages;
    },
    postsList(){
        let pageObj = _.filter(this.pagesList, {template: "blog"});
        return pageObj[0].posts;
    }
}

}
</script>
<style scoped>
</style>