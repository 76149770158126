<template>
    <div class="section-container">
        <SectionLayoutSwitcher :index="index" :layout="layout"></SectionLayoutSwitcher>
        
        <component :is="layout.component" :cssClass="layout.cssClass" :content="content"></component>
    </div>
</template>
<script>
import SectionLayoutSwitcher from '../toolbar/SectionLayoutSwitcher.vue'

//Offer Components
import OfferView01 from './offer/OfferView01.vue'
import OfferView02 from './offer/OfferView02.vue'
import OfferView03 from './offer/OfferView03.vue'

//Benefits Components
import BenefitsView01 from './benefits/BenefitsView01.vue'
import BenefitsView02 from './benefits/BenefitsView02.vue'
import BenefitsView03 from './benefits/BenefitsView03.vue'

//About Components
import AboutView01 from './about/AboutView01.vue'

//Blog Components
import BlogView01 from './blog/blogView01.vue'



export default{
data(){
    return {
    }
},
name: 'PageSectionContainer',
props: ["layout", "content", "index"],
components: {
    SectionLayoutSwitcher,
    OfferView01,
    OfferView02,
    OfferView03,
    BenefitsView01,
    BenefitsView02,
    BenefitsView03,
    AboutView01,
    BlogView01
}
}
</script>
<style scoped>
</style>