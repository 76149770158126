<template>
    <div class="section-container start-view">
        <SectionLayoutSwitcher :component="component" :layout="layout"></SectionLayoutSwitcher>
        <component :is="layout.component" :cssClass="layout.cssClass" :content="content"></component>
    </div>
</template>
<script>
import SectionLayoutSwitcher from '../../toolbar/SectionLayoutSwitcher.vue'


import StartView01 from './StartView01.vue'
import StartView02 from './StartView02.vue'
import StartView03 from './StartView03.vue'
import StartView04 from './StartView04.vue'
import StartView05 from './StartView05.vue'



export default{
name: 'StartViewMain',
data(){
    return{
        component: 'startView'
    }
},
props: ["content", "layout"],
components: {
    SectionLayoutSwitcher,
    StartView01,
    StartView02,
    StartView03,
    StartView04,
    StartView05
  }
}
</script>
<style scoped>
</style>