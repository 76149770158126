import _ from "lodash";

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
function randomIndex(arr, excludeIndex) {
  let indexes = Object.keys(arr);
  indexes.splice(excludeIndex, 1);
  return indexes[Math.floor(Math.random() * indexes.length)];
}


function returnUniqueImages(state, amountOfImages){
    let availableImages = state.pageContent.media.images,
    currentUsed = state.currentUsedMedia.images;

    let tempAvailableImages = [...availableImages];
    currentUsed.forEach(elm => {
        let index = _.findIndex(availableImages, elm);
        tempAvailableImages.splice(index, 1);
    });
    
    const randomImages = getRandom(tempAvailableImages, amountOfImages);
    randomImages.forEach(elm => {
      currentUsed.push(elm);
    });
    return randomImages;
}

export default {
  setTemplateColors(state, payload) {
    state.templateConfig.colors.major = payload.major;
    state.templateConfig.colors.header = payload.header;
    state.templateConfig.colors.content = payload.content;

    const root = document.documentElement;
    const templateConfig = state.templateConfig;
    root.style.setProperty("--majorColor", templateConfig.colors.major);
    root.style.setProperty("--headersColor", templateConfig.colors.header);
    root.style.setProperty("--contentColor", templateConfig.colors.content);
  },
  setTypography(state, payload) {
    state.templateConfig.typo.headers = payload.headers;
    state.templateConfig.typo.content = payload.content;

    const root = document.documentElement;
    const templateConfig = state.templateConfig;
    root.style.setProperty("--headersFont", templateConfig.typo.headers);
    root.style.setProperty("--contentFont", templateConfig.typo.content);
  },
  setFontWeight(state, payload) {
    state.templateConfig.headers.weight = payload.weight;
    const root = document.documentElement;
    root.style.setProperty("--headersWeight", payload.weight);
  },
  changeSectionLayout(state, value) {
    let newIndex = null;
    if (value.component === "startView" || value.component === "footer") {
      let currentLayoutIndex = _.findIndex(
        state.availableLayouts[value.component],
        { component: value.layout.component, cssClass: value.layout.cssClass }
      );
      if (
        currentLayoutIndex <
        state.availableLayouts[value.component].length - 1
      ) {
        newIndex = currentLayoutIndex + 1;
      } else {
        newIndex = 0;
      }
      state.homepageLayout[value.component] =
        state.availableLayouts[value.component][newIndex];
    } else {
      let currentLayoutIndex = _.findIndex(
        state.availableLayouts[value.layout.type],
        { component: value.layout.component, cssClass: value.layout.cssClass }
      );

      if (state.availableLayouts[value.layout.type].length > 1) {
        if (
          currentLayoutIndex <
          state.availableLayouts[value.layout.type].length - 1
        ) {
          newIndex = currentLayoutIndex + 1;
        } else {
          newIndex = 0;
        }
        state.homepageLayout.sections[value.index].component =
          state.availableLayouts[value.layout.type][newIndex].component;
        state.homepageLayout.sections[value.index].cssClass =
          state.availableLayouts[value.layout.type][newIndex].cssClass;

      }
    }
  },
  moveSectionUp(state, payload) {
    let f = state.homepageLayout.sections.splice(payload.index, 1)[0];
    state.homepageLayout.sections.splice(payload.index - 1, 0, f);
  },
  moveSectionDown(state, payload) {
    let f = state.homepageLayout.sections.splice(payload.index, 1)[0];
    state.homepageLayout.sections.splice(payload.index + 1, 0, f);
  },
  changeLanguage(state, payload) {
    return (state.appSettings.lang = payload.value);
  },
  setPageContent(state, payload) {
    return (state.pageContent = payload);
  },
  generateNewLayout(state) {

    

    const layouts = state.availableLayouts,
      layoutsConfig = state.availableLayoutSettings;

    let currentLayout = state.homepageLayout,
      currentTemplateConfig = state.templateConfig,
      newLayout = {
        header: {},
        startView: {},
        sections: [],
        footer: {},
      },
      newTemplateConfig = {
        colors: {},
        typo: {},
        headers: {},
      },
      rand = null;

    for (const key in currentLayout) {
      if (key !== "sections") {
        let index = _.findIndex(layouts[key], {
          component: currentLayout[key].component,
          cssClass: currentLayout[key].cssClass,
        });
        rand = randomIndex(layouts[key], index);
        newLayout[key]["component"] = layouts[key][rand].component;
        newLayout[key]["cssClass"] = layouts[key][rand].cssClass;
      }
    }

    //Set new Sections
    currentLayout.sections.forEach((element) => {
      let index = _.findIndex(layouts[element.type], {
        component: element.component,
        cssClass: element.cssClass,
      });

      if (layouts[element.type].length > 1) {
        rand = randomIndex(layouts[element.type], index);
      } else {
        rand = index;
      }
      let tempLayout = { ...layouts[element.type][rand] };
      newLayout.sections.push(tempLayout);
      newLayout.sections[newLayout.sections.length - 1]["type"] = element.type;
    });

    //shuffle sectins
    //newLayout.sections = _.shuffle(newLayout.sections);

    //Set New template config
    let colorIndex = _.findIndex(layoutsConfig.colorPalettes, {
        major: currentTemplateConfig.colors.major,
        header: currentTemplateConfig.colors.header,
        content: currentTemplateConfig.colors.content,
      }),
      typoIndex = _.findIndex(layoutsConfig.typoLibrary, {
        headers: currentTemplateConfig.typo.headers,
        content: currentTemplateConfig.typo.content,
      }),
      headersIndex = _.findIndex(layoutsConfig.fontWeightLibrary, {
        weight: currentTemplateConfig.headers.weight,
      });

    let NewColors = {
        ...layoutsConfig.colorPalettes[
          randomIndex(layoutsConfig.colorPalettes, colorIndex)
        ],
      },
      NewTypo = {
        ...layoutsConfig.typoLibrary[
          randomIndex(layoutsConfig.typoLibrary, typoIndex)
        ],
      },
      newFontWeight = {
        ...layoutsConfig.fontWeightLibrary[
          randomIndex(layoutsConfig.fontWeightLibrary, headersIndex)
        ],
      };

    newTemplateConfig.colors = NewColors;
    newTemplateConfig.typo = NewTypo;
    newTemplateConfig.headers = newFontWeight;

    state.homepageLayout = newLayout;
    state.templateConfig = newTemplateConfig;

    window.scrollTo(0, 0);
  },
  resetCurrentUsedImages(state){
    return state.currentUsedMedia.images = [];
  },
  setSlierImages(state) {
      let index = _.findIndex(state.pageContent.pages, {path: 'home'});
      state.pageContent.pages[index].startView['images'] = returnUniqueImages(state, 3);
  },
  setActivePagesImages(state){
    let pages = state.pageContent.pages;  
    pages.forEach((elm) => {
      let images = elm.img;
      
      if(elm.path === "about" || elm.path === "blog" || elm.path === "contact" || elm.path === "offer"){
        elm['thumbnail'] = returnUniqueImages(state, 1);
      } else {
        if(images){
          let index = _.findIndex(images, {active: true});
          if(index !== -1){
             images[index]["active"] = false;
           }
           if(images.length > index+1){
             images[index+1]["active"] = true;
           } else {
            images[0]["active"] = true;
    
           }
        }
      }

      if(elm.template === "blog"){
          elm.posts.forEach((post) => {
           post['thumbnail'] = returnUniqueImages(state, 1);
          });
      }

    });
  },
  setActiveVideo(state) {
    
    let pageIndex = _.findIndex(state.pageContent.pages, {path: 'home'});
    state.pageContent.pages[pageIndex].startView['video'] = [...state.pageContent.media.video];
    let videos = state.pageContent.pages[pageIndex].startView.video;
    
    let random = getRandom(videos, 1);

    videos.forEach((elm) => {
      if (elm.active) {
        elm.active = false;
      }
    });

    let index = _.findIndex(videos, random[0]);
    videos[index]["active"] = true;

    
  },
  addDataToObjData(state){
    state.pageContent['media'] = state.mediaTemp;
  },
  openPopup(state, payload){
    state.appSettings.popup = payload;
  },
  setImages(state){
    console.log(state);
    
  }
};
