<template>
  <section id="start-view" :class="cssClass">
    <div class="wrapper">
      <div class="content">
        <h2>{{ content.header }}</h2>
        <p>{{ content.subHeader }}</p>
        <router-link to="/demo/offer">
          <button class="global v2">{{ content.btn }}</button>
        </router-link>
      </div>
    </div>
    <button type="button" class="scroll_down scroll_next_section_landing" id="scroll_down"></button>
    <div class="cover"></div>
    <div id="video"></div>
  </section>
</template>
<script>
import _ from 'lodash';
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import vidbg from "@/assets/js/vidbg.js";


import { getImgUrl } from "@/mixins/mixins.js";
export default {
  name: "StartView03",
  data(){
    return{
      videoInstance: null,
      videoAttributes: {
          loop: true,
          muted: true,
        },

    }
  },
  props: ["content", "cssClass"],
  mixins: [getImgUrl],
  computed: {
    videoOptions(){
      return {
          mp4: this.getActiveVideo(this.content.video).url, // URL or relative path to MP4 video
          overlay: false, // Boolean to display the overlay or not
          overlayColor: "#000", // The overlay color as a HEX
          overlayAlpha: 0.3,
        }
    }
  },
  watch: {
    videoOptions(){
      this.videoInstance.destroy();
      this.videoInstance = new vidbg("#start-view #video", this.videoOptions, this.videoAttributes);

    }
  },
  methods: {
    getActiveVideo(arr) {
      return _.filter(arr, { active: true })[0];
    },
  },
  mounted() {
        this.videoInstance = new vidbg("#start-view #video", this.videoOptions, this.videoAttributes);
  },
};
</script>
<style scoped></style>