<template>
  <div class="header">
    <h2>{{ header }}</h2>
    <p v-if="sub">{{ sub }}</p>
  </div>
</template>
<script>
export default {
  name: "SectionHeader",
  props: ["header", "sub"],
};
</script>
<style scoped></style>
