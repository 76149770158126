<template>
  <li>
    <div class="card">
      <div class="scrollArea">
        <div class="inner">
          <component :is="window"></component>
        </div>
      </div>
    </div>

    <button class="toolbarItem" @click="setActive($event)">
      <div class="icon">
        <img class="off" :src="getImgUrl(icon)" alt="" />
        <img
          class="on"
          :src="getImgUrl(iconActive)"
          alt=""
        />
      </div>
      <span>{{ name }}</span>
    </button>
  </li>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import colorSettings from './colorSettings.vue'
import typoSettings from './typoSettings.vue'


export default {
  name: "settingsTooltip",
  props: ['name', 'icon', 'iconActive', 'window', 'index'],
  components: {
     colorSettings, typoSettings
    },
  methods: {
        setActive(e){
          if(!$(e.currentTarget).parent().hasClass('active')){
              $('#appDesignerToolbar ul.tooltip-nav li').removeClass('active');
            }
            $(e.currentTarget).parent().toggleClass('active');
        },
        getImgUrl(pic) {
            return require('@/assets/images/app/'+pic)
        }
    },
};
</script>
<style scoped></style>
