<template>
    <footer  :class="cssClass">
        <div class="wrapper">
        <div class="main-footer">
                <div class="left-side">
                <div class="logo">
                    <span>{{ content.global.companyName }}</span>
                    </div>
                    <ul class="social-media">
                <li>
                <button><i class="fa-brands fa-square-facebook"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-instagram"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-x-twitter"></i></button
                >
                </li>
            </ul>
                </div>
                <div class="right-side">
                    <div class="col">
                        <span class="header">{{ this.$store.getters.currentLang.footer.headerNavMenu }}</span>
                        <ul>
                            <li
                            v-for="(item, key) in content.global.menu"
                            :key="key"
                            :class="{ active: key === 0 }"
                            >
                            <router-link :to="'/demo/'+item.path">{{ item.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <span class="header">{{ this.$store.getters.currentLang.footer.headerNavServices }}</span>
                        <ul>
                            <li :key="key" v-for="(item, key) in offerItems"><router-link :to="'/demo/'+item.path">{{item.name}}</router-link></li>
                        </ul>
                    </div>
                    <div class="col">
                        <span class="header">{{ this.$store.getters.currentLang.footer.headerContact }}</span>
                        <div class="content">
                            <p><b>{{content.global.address}}</b></p>
                            <p><a href="#">{{content.global.tel}}</a></p>
                            <p><a href="#">{{content.global.email}}</a></p>
                            <p>NIP: 123 123 123<br/>
                                KRS: 123 123 123<br/>
                                REGON: 123 123 123</p>
                        </div>
                    </div>
                </div>
        </div>
        <div class="copy-footer">
            <div class="left-side">
                <p>© {{content.global.companyName}} 2024. {{ this.$store.getters.currentLang.footer.copyright }}</p>
            </div>
            <div class="right-side">
                <p>Powered by <a href="https://thelion.pl/" target="_blank" rel="nofollow">THE LION</a></p>
            </div>
        </div>
    </div>
        </footer>
</template>
<script>
import _ from 'lodash';
export default{
name: 'FooterView02',
props: ["content", "cssClass"],
computed: {
    pagesList(){
        return this.$store.state.pageContent.pages;
    },
    offerItems(){
        return _.filter(this.pagesList, 
            (o) => { return o.template === "offer" && o.path !== 'offer'; });
    }
  }
}
</script>
<style scoped>
</style>