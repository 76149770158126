<template>
    <div>
        <span class="box-header">{{ this.$store.getters.currentLang.toolbar.typo }}</span>
        <v-select
          label="Font weight"
          item-title="name"
          item-value="weight"
          :items="fontWeightOptions"
          variant="outlined"
          density="compact"
          v-model="fontWeight"
          return-object
          @update:modelValue="changeFontWeight($event)"
        ></v-select>
        <ul class="typo-radio-select">
          <li v-for="(item, key) in typoLibrary" :key="key" @click="setTypography(key)" :class="{'on': key === activeTypo}">
            <span class="header" :style="{'font-family': item.headers}">Debitis quasi quis accusamus</span>
            <span class="content" :style="{'font-family': item.content}">Autem officiis odio animi deserunt</span>
          </li>
        </ul>
        <v-alert
        style="margin: 6px 0px;"
        type="info"
        variant="tonal"
        class="text-caption"
        :text="this.$store.getters.currentLang.toolbar.typoInfo"
      ></v-alert>
    </div>
</template>
<script>
import _ from "lodash";

export default{
name: 'colorSettings',
data(){
  return{
  }
},
computed: {
  typoLibrary(){
    return this.$store.state.availableLayoutSettings.typoLibrary;
  },
  fontWeightOptions(){
    return this.$store.state.availableLayoutSettings.fontWeightLibrary;
  },
  templateConfig(){
    return this.$store.state.templateConfig;
  },
  activeTypo(){
    let index = _.findIndex(this.typoLibrary, {
      headers: this.templateConfig.typo.headers,
      content: this.templateConfig.typo.content
    });
    return index;
  },
  fontWeight(){
    let index = _.findIndex(this.fontWeightOptions, {weight: this.templateConfig.headers.weight});
     return this.fontWeightOptions[index];
  }
},
methods: {
  setTypography(key){
    return this.$store.commit("setTypography", this.typoLibrary[key]);
  },
  changeFontWeight(event){
    return this.$store.commit("setFontWeight", event);
  },
  setFontWeight() {
    let index = _.findIndex(this.fontWeightOptions, {weight: this.templateConfig.headers.weight});
    this.fontWeight = this.fontWeightOptions[index];
  }
},
mounted(){
 // this.setFontWeight();
}
}
</script>
<style scoped>
</style>