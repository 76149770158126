<template>
  <div class="waitingView">
    <div class="wrapper">
      <div class="card">
        <template v-if="!err">
          <div id="preloader">
            <div id="loader"></div>
          </div>
          <ul class="steps">
            <li
              :class="{ done: indexIn(key) }"
              v-for="(item, key) in steps"
              :key="key"
            >
              {{ item }}
            </li>
          </ul>
        </template>
        <template v-else>
            <p>{{this.$store.getters.currentLang.generatorForm.waitingPage.err}}</p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "WaitingPage",
  data() {
    return {
      done: [],
    };
  },
  computed: {
    steps(){
        return this.$store.getters.currentLang.generatorForm.waitingPage.steps;
    }
  },
  props: ["err"],
  methods: {
    indexIn(key) {
      return _.findIndex(this.done, function (o) {
        return o == key;
      }) >= 0
        ? true
        : false;
    },
  },
  mounted() {
    let i = 0;
    let interval = setInterval(() => {
      this.done.push(i);
      i++;
      if (i === this.steps.length-1) {
        clearInterval(interval);
      }
    }, 10000);
  },
};
</script>
<style scoped></style>
