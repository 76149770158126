<template>
    <div class="generator-form">
        <div class="top-info-bar">
            <div class="wrapper">
                <p>{{ this.$store.getters.currentLang.generatorForm.topBar }}</p>
            </div>
        </div>
        <div class="form-wrapper">
            <div class="appHeader">
                <img src="@/assets/images/app/logo.svg" alt="">
                <LanguagSwitcher></LanguagSwitcher>
            </div>
            <template v-if="!submited">
                <div class="header">
                    <h1 v-html="this.$store.getters.currentLang.generatorForm.header"></h1>
                    <p v-html="this.$store.getters.currentLang.generatorForm.subHeader"></p>
                </div>
                <FormStep1 
                :industries="industries" 
                @setIndustry="setIndustry"
                :disabledStep="disableSetp1"
                ></FormStep1>
                <FormStep2 
                v-if="form.industry && availableServices[0]" 
                :availableServices="availableServices"
                @setServices="setServices"
                ></FormStep2>
                <FormStep3
                v-if="form.services[0]"
                @submitForm="submitForm"
                ></FormStep3>
            </template>

            <WaitingPage :err="err" v-else></WaitingPage>

        </div>
    </div>
</template>
<script>
import axiosConfig from '../axios-config';

import LanguagSwitcher from './form/LanguagSwitcher.vue'
import FormStep1 from './form/FormStep1.vue'
import FormStep2 from './form/FormStep2.vue'
import FormStep3 from './form/FormStep3.vue'
import WaitingPage from './form/WaitingPage.vue'


export default{
name: 'GeneratorForm',
components: {
    LanguagSwitcher,
    FormStep1,
    FormStep2,
    FormStep3,
    WaitingPage
},
data () {
      return {
        industries: require("../assets/data/industries_"+this.$store.state.appSettings.lang+".json"),
        customIndustry: false,
        form: {
            industry: "",
            services: []
        },
        availableServices: [],
        submited: false,
        err: false
      }
    },
    props: ['lang'],
    created(){
        this.$store.commit("changeLanguage", {value: this.lang});
    },
    computed: {
        appLang(){
            return this.$store.state.appSettings.lang;
        },
        disableSetp1(){
            if(this.availableServices[0]){
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        setIndustry(payload){
            this.form.industry = payload.industry;
            this.availableServices = payload.services;
        },
        setServices(payload){
            return this.form.services = payload;
        },
        submitForm(payload){
            this.submited = true;
            for (let key in payload) {
                this.form[key] = payload[key];
            }
            this.form['sourceLang'] = this.appLang;
            
            axiosConfig.post("/api/generate", this.form).then(({data}) => { 
                    this.$store.commit("setPageContent", data);
                    console.log(data);
                    this.$router.push('/demo');
                })
                .catch(
                    (err) => {
                        console.log('Err', err);
                        this.err = true;

                    }
                );
            
        }   
    }
}
</script>
<style scoped>
    .v-text-field .v-input__details{
        display: none;
    }
</style>