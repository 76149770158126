<template>
  <div class="step">
    <p class="field-header mb">
      {{this.$store.getters.currentLang.generatorForm.step3.webisteLangLabel}}
    </p>
    <v-select
      :label="this.$store.getters.currentLang.generatorForm.step3.selectLang"
          item-title="name"
          item-value="value"
          :items="availableLanguages"
          v-model="form.targetLang"
          variant="outlined"
    ></v-select>

    <p class="field-header mb">{{this.$store.getters.currentLang.generatorForm.step3.companyNameHeader}}</p>
    <v-text-field v-model="form.companyName" :label="this.$store.getters.currentLang.generatorForm.step3.companyNameLabel" variant="outlined"></v-text-field>

    <v-alert
        v-if="form.companyName.length > 16"
        style="margin: 0px 0px 18px 0px;"
        :text="this.$store.getters.currentLang.generatorForm.step3.companyNameInfo"
        type="info"
        variant="tonal"
    ></v-alert>

    <p class="field-header mb">{{this.$store.getters.currentLang.generatorForm.step3.emailInputHeader}}</p>
    <v-text-field v-model="form.email"  
    type="email" 
    autocomplete="on"
    :label="this.$store.getters.currentLang.generatorForm.step3.emailInputLabel" 
    variant="outlined"
    :error-messages="emailValidation"
    required
    @blur="emailFieldTouched = true"
    ></v-text-field>

    <v-checkbox
      v-model="form.allowance"
      color="indigo"
      value="true"
      hide-details
      :label="this.$store.getters.currentLang.generatorForm.step3.allowance"
    ></v-checkbox>

    <v-btn
      class="flex-grow-1"
      height="48"
      :loading="loading"
      @click="submitForm"
      color="indigo"
      :disabled="formInvalid"
    >
      {{this.$store.getters.currentLang.generatorForm.step3.submitBtn}}
      <template v-slot:loader>
        <v-progress-circular color="white" indeterminate></v-progress-circular>
      </template>
    </v-btn>
    <v-alert
        v-if="waitingInfo"
        style="margin: 22px 0px 0px 0px;"
        :text="this.$store.getters.currentLang.generatorForm.step3.waitingInfo"
        type="info"
        variant="tonal"
    ></v-alert>

  </div>
</template>
<script>
export default {
  name: "FormStep3",
  data() {
    return {
      loading: false,
      emailFieldTouched: false,
      waitingInfo: false,
      form: {
        targetLang: "pl",
        companyName: "",
        email: null,
        allowance: false
      },
      availableLanguages: [
        {
          value: "pl",
          name: this.$store.getters.currentLang.generatorForm.step3.availableLanguages.pl,
        },
        {
          value: "en",
          name: this.$store.getters.currentLang.generatorForm.step3.availableLanguages.en,
        },
        {
          value: "ua",
          name: this.$store.getters.currentLang.generatorForm.step3.availableLanguages.uk,
        },
        {
          value: "de",
          name: this.$store.getters.currentLang.generatorForm.step3.availableLanguages.de,
        },
      ],
    };
  },
  computed: {
    formInvalid(){
      if(this.form.allowance && !this.emailValidation){
        return false;
      } else {
        return true;
      }
    },
    emailValidation(){
      const pattern=/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!pattern.test(this.form.email) && this.emailFieldTouched) {
        return "Twój email jest nieprawidłowy";
      } else {
        return false;
      }
    }
  },
  methods: {
    submitForm(){
      this.loading = true;
      setTimeout(() => {
        this.waitingInfo = true;
      }, 5000);
      this.$emit("submitForm", this.form);
    }
  },
};
</script>
<style scoped></style>
