<template>
        <div class="language-select">
            <div class="switcher">
                <v-select
                    :label="this.$store.getters.currentLang.language"
                    item-title="name"
                    item-value="value"
                    :items="availableLanguages"
                    variant="outlined"
                    density="compact"
                    v-model="lang"
                    return-object
                    hide-details
                    @update:modelValue="changeLang()"
                ></v-select>
            </div>
        </div>
</template>
<script>
import _ from 'lodash';
export default{
name: 'LanguageSwitcher',
data(){
    return {
        lang: {},
        availableLanguages: [
        {
          value: "pl",
          name: "Polski",
        },
        {
          value: "en",
          name: "English",
        },
        {
          value: "de",
          name: "Deutsch",
        }
      ],
    }
},
methods: {
    changeLang(){
        this.$store.commit("changeLanguage", this.lang);
        return this.$router.push('/'+this.lang.value);
    }
},
mounted(){
  let index = _.findIndex(this.availableLanguages, {
          value: this.$store.state.appSettings.lang
        });
  this.lang = this.availableLanguages[index];
}
}
</script>
<style scoped>
</style>