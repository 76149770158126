<template>
  <section id="offer-benefits" :class="cssClass">
    <div class="wrapper">
      <SectionHeader
        :header="content.header"
        :sub="content.subHeader"
      ></SectionHeader>
      <div class="tiles">
        <div class="item" :key="key" v-for="(item, key) in content.items">
          <div class="inner">
            <div class="icon">
              <i class="fa-solid" :class="item.icon"></i>
            </div>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SectionHeader from "../template-parts/SectionHeader.vue";
import { getImgUrl } from "@/mixins/mixins.js";

export default {
  name: "BenefitsView01",
  components: {
    SectionHeader,
  },
  mixins: [getImgUrl],
  props: ["content", "cssClass"],
};
</script>
<style scoped></style>
