<template>
    <div>
        <span class="box-header">{{ this.$store.getters.currentLang.toolbar.colors }}</span>
        
        <ul class="color-radio-select">
          <li v-for="(item, key) in colorPalettes" :key="key" :class="{'on': key === activeColor}" @click="setColorPalette(key)">
            <div class="circles">
              <div class="item" :style="{background: item.major}"></div>
              <div class="item" :style="{background: item.header}"></div>
            </div>
            <span class="name">{{ item.name }}</span>
          </li>
        </ul>

        <v-alert
        style="margin: 6px 0px;"
        type="info"
        variant="tonal"
        class="text-caption"
        :text="this.$store.getters.currentLang.toolbar.colorsInfo"
      ></v-alert>
    </div>
</template>
<script>
import _ from "lodash";
export default{
name: 'colorSettings',
data(){
  return{
  }
},
computed: {
  colorPalettes(){
    return this.$store.state.availableLayoutSettings.colorPalettes;
  },
  templateConfig(){
    return this.$store.state.templateConfig;
  },
  activeColor(){
    let activeColorIndex = _.findIndex(this.colorPalettes, {
      major: this.templateConfig.colors.major,
      header: this.templateConfig.colors.header,
      content: this.templateConfig.colors.content
    });
    return activeColorIndex;
  }
},
methods: {
  setColorPalette(key){
    return this.$store.commit("setTemplateColors", this.colorPalettes[key]);
  }
}
}
</script>
<style scoped>
</style>