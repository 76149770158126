<template>
    <div id="appDesignerToolbar">
        <div class="wrapper">
            <button class="toolbarItem ai" @click="generateNewLayout()">
                <div class="icon">
                        <img class="on" src="@/assets/images/app/magic-tool-active.svg" alt="">
                    </div>
                    <span>{{this.$store.getters.currentLang.toolbar.newDesign}}</span>
            </button>
            <ul class="tooltip-nav">
                <settingsTooltip v-for="(item, key) in toolbarOptions" 
                    :key="key"    
                    :index="key"
                    :name="item.name" 
                    :icon="item.icon" 
                    :iconActive="item.iconActive"
                    :window="item.componentName">
                </settingsTooltip>
            </ul>
            <button class="toolbarItem newPhotos" @click="generateNewPhotos()">
            <div class="icon">
                <img class="off" src="@/assets/images/app/rotate-photo2.svg" alt="" />
            </div>
            <span>{{this.$store.getters.currentLang.toolbar.newPhotos}}</span>
            </button>
            <button class="toolbarItem newPhotos" @click="openPopup()">
            <div class="icon">
                <img class="off" src="@/assets/images/app/edit-content.svg" alt="" />
            </div>
            <span>{{this.$store.getters.currentLang.toolbar.editContent}}</span>
            </button>

        <!-- <div class="d-flex  flex-column device-switcher"
            style=" height:37px">
            <v-btn-toggle
            v-model="device"
            @click="changeDevice()"
            variant="outlined"
            divided
            >
            <v-btn 
            value="desktop"
            size="small"
            icon="mdi-monitor"></v-btn>
            <v-btn 
            value="mobile"
            size="small"
            icon="mdi-cellphone"></v-btn>
            </v-btn-toggle>
        </div> -->


            <button class="toolbarItem createWeb" @click="openPopup()">
                <div class="icon">
                        <img class="on" src="@/assets/images/app/play.svg" alt="">
                        
                    </div>
                    <span>{{this.$store.getters.currentLang.toolbar.saveWebsite}}</span>
            </button>
        </div>
    </div>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import settingsTooltip from './settingsTooltip.vue'


export default{
name: 'designerToolbar',
data(){
    return{
        device: 'desktop',
        toolbarOptions: [
            {
                name: this.$store.getters.currentLang.toolbar.colors,
                icon: 'color-palette.svg',
                iconActive: 'color-palette-active.svg',
                componentName: 'colorSettings'
            },
            {
                name: this.$store.getters.currentLang.toolbar.typo,
                icon: 'typography.svg',
                iconActive: 'typography-active.svg',
                componentName: 'typoSettings'
            }
        ]
    }
},
components: {
    settingsTooltip
},
methods: {
    changeDevice(){
        return this.$emit("changeDevice", this.device);
    },
    generateNewPhotos(){
   
        this.$store.commit("resetCurrentUsedImages");
        
        this.$store.commit("setSlierImages");
        this.$store.commit("setActivePagesImages");
        this.$store.commit("setActiveVideo");
    },
    generateNewLayout(){
        this.generateNewPhotos();
        this.$store.commit("generateNewLayout");
    },
    openPopup(){
       return this.$emit("openFeedbackPopup", true);
    }
},
mounted(){
    
  var mcontent = "#appDesignerToolbar ul li, .v-overlay-container";
  $(document).on("mouseup touchend focusin", function functionName(e) {
    if (!$(mcontent).is(e.target) && $(mcontent).has(e.target).length === 0) {
        $('#appDesignerToolbar ul li').removeClass('active');
    }
  });
}
}
</script>
<style scoped>
</style>