<template>
    <footer  :class="cssClass">
        <div class="wrapper">
            <div class="main-footer">
            <div class="logo">
                <a href="#">
                <span>{{ content.global.companyName }}</span>
                </a>
            </div>
            <ul class="social-media">
                <li>
                <button><i class="fa-brands fa-square-facebook"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-instagram"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-x-twitter"></i></button
                >
                </li>
            </ul>
            </div>
            <div class="copy">
            <div class="left">
                <ul>
                <li
                  v-for="(item, key) in content.global.menu"
                  :key="key"
                  :class="{ active: key === 0 }"
                >
                  <router-link :to="'/demo/'+item.path">{{ item.name }}</router-link>
                </li>
              </ul>
            </div>
            <div class="right">
                <p>
                Powered by
                <a href="https://thelion.pl/" rel="nofollow" target="_blank"
                    >THE LION</a
                >
                </p>
            </div>
            </div>
        </div>
        </footer>
</template>
<script>
export default{
name: 'FooterView01',
props: ["content", "cssClass"]
}
</script>
<style scoped>
</style>