<template>
  <section id="offer-benefits" :class="cssClass">
    <div class="wrapper">
      <SectionHeader
        :header="content.header"
        :sub="content.subHeader"
      ></SectionHeader>
      <div class="items">
        <div class="item" :key="key" v-for="(item, key) in content.items">
          <div class="icon">
            <i class="fa-solid" :class="item.icon"></i>
          </div>
          <div class="desc">
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SectionHeader from "../template-parts/SectionHeader.vue";

export default {
  name: "BenefitsView02",
  components: {
    SectionHeader,
  },
  props: ["content", "cssClass"],
};
</script>
<style scoped></style>
