<template>
<section id="page-header">
                <div class="wrapper">
                    <div class="content">
                        <h1>{{title}}</h1>
                        <p>{{subTitle}}</p>
                    </div>
                </div>
                <div class="cover-color"></div>
                <div class="cover"></div>
                <img v-if="ifOfferPage" :src="getActiveImg(thumbnail).url" :alt="getActiveImg(thumbnail).alt" class="bg">
                <img v-else :src="pageThumbnail[0].url" :alt="pageThumbnail[0].alt" class="bg">

            </section>
</template>
<script>
import { setActiveOfferImages } from "@/mixins/mixins.js";

export default{
name: 'PageHeader',
  mixins: [setActiveOfferImages],
props: ["title", "subTitle", "thumbnail", "path", "pageThumbnail"],
    computed: {
        ifOfferPage(){
            if(this.path === "offer" ||
            this.path === "about" ||
            this.path === "contact" ||
            this.path === "blog" 
            ) {
                return false;
            } else {
                return true;
            }
        }
    }
}
</script>
<style scoped>
</style>