<template>
  <section id="start-view" :class="cssClass">
    <div class="wrapper">
      <div class="content">
        <h2>{{ content.header }}</h2>
        <p>{{ content.subHeader }}</p>
        <router-link to="/demo/offer">
          <button class="global v2">{{ content.btn }}</button>
        </router-link>
      </div>
    </div>

    <div class="slick-slider">
      <div class="item" :key="key" v-for="(item, key) in slidersToShow">
        <div class="cover"></div>
        <img :src="item.url" :alt="item.alt" loading="lazy" />
      </div>
    </div>
  </section>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import { getImgUrl, getRandom } from "@/mixins/mixins.js";
import "@/assets/js/slick.min.js";

export default {
  name: "StartView01",
  props: ["content", "cssClass"],
  mixins: [getImgUrl, getRandom],
  computed: {
    slidersToShow(){
      return this.content.images;
    }
  },
  updated(){
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);
  },
  mounted() {
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);

    $("section#start-view .slick-slider").slick({
      slidesToShow: 1,
      dots: true,
      arrows: false,
      draggable: false,
      infinite: true,
      lazyLoad: "ondemand",
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 800,
      pauseOnHover: false,
      pauseOnFocus: false,
      fade: true,
    });
  }
};
</script>
<style scoped></style>
