<template>
  <section id="start-view" :class="cssClass">
    <div class="wrapper">
      <div class="content">
        <h1>{{ content.header }}</h1>
        <p>{{ content.subHeader }}</p>
        <router-link to="/demo/offer">
          <button class="global v2">{{ content.btn }}</button>
        </router-link>
      </div>
    </div>
    <img :src="bgImg.url" :alt="bgImg.alt" class="bg">

  </section>
</template>
<script>

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import { getImgUrl, getRandom } from "@/mixins/mixins.js";

export default {
  name: "StartView04",
  props: ["content", "cssClass"],
  mixins: [getImgUrl, getRandom],
  computed: {
    bgImg(){
      return  this.content.images[0];
    }
  },
  updated(){
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);
  },
  mounted() {
    setTimeout(function () {
      $("#start-view").addClass("on");
    }, 50);
  }
};
</script>
<style scoped></style>