<template>
    <section id="about-company" :class="cssClass">
        <div class="wrapper">
            <div class="img-side">
                <img loading="lazy" 
                :src="aboutPageContent.thumbnail[0].url" :alt="aboutPageContent.thumbnail[0].alt">
            </div>
            <div class="content-side global-content-config" v-html="aboutPageContent.content">
                
            </div>
        </div>
    </section>
</template>
<script>
import _ from 'lodash';
import { getImgUrl, setActiveOfferImages } from "@/mixins/mixins.js";
export default{
name: 'AboutView01',
props: ["content", "cssClass"],
mixins: [getImgUrl, setActiveOfferImages],
computed: {
    pagesList(){
        return this.$store.state.pageContent.pages;
    },
    aboutPageContent(){
        return _.filter(this.pagesList, {path: "about"})[0];
    }
}
}
</script>
<style scoped>
</style>