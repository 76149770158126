import _ from 'lodash';


export const getImgUrl = {
  methods: {
    getImgUrl(pic) {
      //return require('@/assets/'+pic)
      if (pic) {
        return pic.url;
      } else {
        return "https://images.unsplash.com/photo-1716847214513-dfac4f00635b?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
      }
    },
  },
};

export const getRandom = {
  methods: {
    getRandom(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
  },
};

export const setActiveOfferImages = {
  methods: {
    getActiveImg(arr) {
      return _.filter(arr, { active: true })[0];
    },
    setActiveOfferImages() {
      let index = 0;
      this.content.items.forEach((elm) => {
        this.$store.commit("setActiveOfferImages", {
          index,
          img: this.getRandom(elm.img, 1),
        });
        index++;
      });
    },
  },
};
