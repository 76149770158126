<template>
  <div>
    <div class="website-view-container" :class="device">
      <HeaderMain 
      :layout="layout.header" 
      :content="content.global"
      ></HeaderMain>
      <component
      :is="pageComponent"
      :layout="layout"
      :content="content"
      :path="path"
      ></component>
      <FooterMain
      :content="content"
      :layout="layout.footer" 
      ></FooterMain>
    </div>

    <designerToolbar @changeDevice="changeDevice" @openFeedbackPopup="openFeedbackPopup"></designerToolbar>
    <PopupFeedback :isActive="feedbackPopup"></PopupFeedback>
  
  </div>
</template>

<script>
import _ from 'lodash';


//app
import designerToolbar from './toolbar/designerToolbar.vue'
import PopupFeedback from './toolbar/PopupFeedback.vue'

//global components   
import HeaderMain from './layouts/header/HeaderMain.vue'
import FooterMain from './layouts/footer/FooterMain.vue'

//pages
import HomePage from './pages/homePage.vue'
import ContactPage from './pages/contactPage.vue'
import OfferPage from './pages/offerPage.vue'
import AboutPage from './pages/aboutPage.vue'
import BlogPage from './pages/blogPage.vue'




export default {
  name: 'WebsiteDemo',
  components: {
    HeaderMain, designerToolbar, FooterMain, PopupFeedback, HomePage, ContactPage, OfferPage, AboutPage, BlogPage
  },
  props: ["path"],
  data() {
    return{
      device: 'desktop'
    }
  },
  computed: {
    feedbackPopup(){
      return this.$store.state.appSettings.popup;
    },
    pageTemplate(){
        let currentPageObj = _.filter(this.content.pages, { path: this.path })[0];
        return currentPageObj.template;
    },
    pageComponent(){
      return this.pageTemplate+"Page";
    },
    content(){
      return this.$store.state.pageContent;
    },
    layout(){
      return this.$store.state.homepageLayout;
    },
    layoutSyles(){
      return this.$store.state.templateConfig;
    }
  },
  watch: {    
        feedbackPopup(){
        },
        layout () {
        },
        layoutSyles () {
          this.setStyles();
        }
    },
    methods: {
      changeDevice(value){
        return this.device = value;
      },
      openFeedbackPopup(){
       return this.$store.commit("openPopup", true);
      },
      setStyles(){
        const root = document.documentElement;
        const templateConfig = this.$store.state.templateConfig;

        //Colors
        root.style.setProperty('--majorColor', templateConfig.colors.major);
        root.style.setProperty('--headersColor', templateConfig.colors.header);
        root.style.setProperty('--contentColor', templateConfig.colors.content);
        //Typo
        root.style.setProperty('--headersFont', templateConfig.typo.headers);
        root.style.setProperty('--contentFont', templateConfig.typo.content);
        //Headers
        root.style.setProperty('--headersWeight', templateConfig.headers.weight);
        
      },
    },
  mounted(){
    console.log(this.content);
  },
  created(){

    //this.$store.commit("addDataToObjData");

    this.$store.commit("setSlierImages");
    this.$store.commit("setActiveVideo");
    this.$store.commit("setActivePagesImages");

    this.$store.commit("generateNewLayout");
    
    this.setStyles();
  }
}
</script>

<style>

</style>
