<template>
  <div class="section-container footer">
    <SectionLayoutSwitcher :component="component" :layout="layout"></SectionLayoutSwitcher>
    <component :is="layout.component" :cssClass="layout.cssClass" :content="content"></component>
  </div>
</template>
<script>
import SectionLayoutSwitcher from '../../toolbar/SectionLayoutSwitcher.vue'

import FooterView01 from './footerView01.vue'
import FooterView02 from './footerView02.vue'


export default {
  name: "FooterMain",
  data(){
    return{
        component: 'footer'
    }
},
  components: {
    SectionLayoutSwitcher,
    FooterView01,
    FooterView02
  },
  props: ["content", "layout"],
};
</script>
<style scoped></style>
