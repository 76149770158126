<template>
        <div>
            <PageHeader :path="path" :title="pageContent.name" :subTitle="pageContent.description" :pageThumbnail="pageContent.thumbnail"></PageHeader>
            <ContactView01 :content="pageContent" :layout="contactLayout"></ContactView01>
        </div>
</template>
<script>
import _ from 'lodash';
import PageHeader from '../layouts/page-header/PageHeader.vue'

import ContactView01 from '../layouts/contact/ContactView01.vue'

export default{
name: 'ContactPage',
props: ["layout", "content", "path"],
data(){
    return {
        contactLayout: {
        "component": "ContactView01",
        "cssClass": "layout01"
        }
    }
},
computed: {
    pageContent(){
        return _.filter(this.content.pages, { path: this.path })[0];
    }
},
components: {
    ContactView01, PageHeader
}
}
</script>
<style scoped>
pre{
    line-height: 1.5;
}
</style>