<template>
    <v-dialog persistent v-model="visible" max-width="500">
        <v-card rounded="lg">
                <v-card-title class="d-flex justify-space-between align-center">
                  <div class="text-h5 text-medium-emphasis ps-2">
                    {{this.$store.getters.currentLang.feedbackForm.header}}
                  </div>
  
                  <v-btn
                    icon="mdi-close"
                    variant="text"
                    @click="switchOff()"
                  ></v-btn>
                </v-card-title>
  
                <v-divider class="mb-4"></v-divider>
  
                <v-card-text>
                  <div class="text-medium-emphasis mb-4">
                    {{this.$store.getters.currentLang.feedbackForm.content}}
                  </div>
  
                  <div class="mb-2">{{this.$store.getters.currentLang.feedbackForm.formHeader}}</div>
  
                  <v-textarea
                    :counter="300"
                    class="mb-2"
                    rows="2"
                    variant="outlined"
                    persistent-counter
                  ></v-textarea>
  
  
                </v-card-text>
  
                <v-divider class="mt-2"></v-divider>
  
                <v-card-actions class="my-2 d-flex justify-end">
                  <v-btn
                    class="text-none"
                    :text="this.$store.getters.currentLang.feedbackForm.close"
                    @click="switchOff()"
                  ></v-btn>
  
                  <v-btn
                    class="text-none"
                    color="primary"
                    :text="this.$store.getters.currentLang.feedbackForm.submit"
                    variant="flat"
                  ></v-btn>
                </v-card-actions>
              </v-card>
          </v-dialog>
  </template>
<script>
export default{
name: 'PopupFeedback',
props: ["isActive"],
data(){
    return{ 
        visible: false
    }
},
watch: {
    isActive(){
        this.visible = this.isActive;
    }
},
methods: {
    switchOff(){
        this.visible = false;
        return this.$store.commit("openPopup", false);
    }
}
}
</script>
<style scoped>
    div{
        line-height: 1.5;
    }
</style>