<template>
    <div class="single-offer-page">
        <PageHeader :path="path" :title="pageContent.name" :subTitle="pageContent.description" :pageThumbnail="pageContent.thumbnail" :thumbnail="pageContent.img"></PageHeader>
        <section v-if="pageContent.content" id="offer-content-page">
            <div class="wrapper global-content-config" v-html="pageContent.content"></div>
        </section>
        <component
        :is="offerSectionLayout.component"
        :cssClass="offerSectionLayout.cssClass" 
        :content="offerTilesContent"
        ></component>

        
    <div class="our-clients" v-if="!pageContent.content">
      <div class="wrapper">
        <div class="sub-header">
          <span class="header">{{aboutContent.clients.header}}</span>
        </div>
        <div class="slick-slider">
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo1.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo2.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo3.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo4.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo5.svg" class="" loading="lazy" />
            </div>
          </div>
          <div class="item">
            <div class="box">
              <img src="@/assets/images/logo6.svg" class="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </div>
</template>
<script>
import _ from 'lodash';
import PageHeader from '../layouts/page-header/PageHeader.vue'


//Offer Components
import OfferView01 from '../layouts/offer/OfferView01.vue'
import OfferView02 from '../layouts/offer/OfferView02.vue'
import OfferView03 from '../layouts/offer/OfferView03.vue'

export default{
name: 'OfferPage',
props: ["layout", "content", "path"],
computed: {
    aboutContent(){
        return _.filter(this.content.pages, { template: 'about' })[0];
    },
    pageContent(){
        return _.filter(this.content.pages, { path: this.path })[0];
    },
    offerSectionLayout(){
        return _.filter(this.$store.state.homepageLayout.sections, { type: "offer" })[0];
    },
    offerTilesContent(){
        return {
            header: this.pageContent.moreOffersHeader,
            subHeader: "",
            items: ""
        }
    }
},
components: {
    PageHeader,
    OfferView01,
    OfferView02,
    OfferView03
}
}
</script>
<style scoped>
</style>