<template>
  <div>
    <section id="header" :class="cssClass">
    <div class="top-bar" :class="{'dark': startViewLightLayout}" v-if="cssClass === 'layout03' || cssClass === 'layout05'">
      <div class="wrapper">
        <div class="left-side">
            <ul class="contact-data">
            <li class="tel" v-if="this.$store.state.pageContent.global.tel">
              <a href="'tel'+this.$store.state.pageContent.global.tel">{{
                this.$store.state.pageContent.global.tel
              }}</a>
            </li>
            <li class="email" v-if="this.$store.state.pageContent.global.email">
              <a
                :href="'mailto:' + this.$store.state.pageContent.global.email"
                >{{ this.$store.state.pageContent.global.email }}</a
              >
            </li>
          </ul>
        </div>
        <div class="right-side">
            <ul class="social-media">
                <li>
                <button><i class="fa-brands fa-square-facebook"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-instagram"></i></button
                >
                </li>
                <li>
                <button><i class="fa-brands fa-x-twitter"></i></button
                >
                </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="main-bar" :class="{'dark': startViewLightLayout}">
      <div class="wrapper">
        <div class="logo">
          <router-link  to="/demo">
            <span class="logo">{{ content.companyName }}</span>
          </router-link>
        </div>
        <div class="right-side">
          <ul class="page-nav">
            <li
              v-for="(item, key) in content.menu"
              :key="key"
            >
              <router-link :to="'/demo/'+item.path">{{ item.name }}</router-link>
              <ul class="sub-menu" v-if="item.subMenu">
                  <li v-for="(elm, key) in item.subMenu" :key="key">
                    <router-link :to="'/demo/'+elm.path">{{ elm.name }}</router-link>

                  </li>
              </ul>
            </li>
          </ul>
          <div class="btn" v-if="cssClass !== 'layout01' && cssClass !== 'layout04' && cssClass !== 'layout05'">
            <router-link to="/demo/contact">
              <button>{{ this.$store.state.pageContent.global.headerBtn }}</button>
            </router-link>
          </div>
          <div class="mobile-menu-icon">
            <button class="menu-toggle">Menu</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="mobile-panel-menu">
        <div class="inner">
            <div class="mobile-menu">
              <ul class="page-nav">
                <li
                  v-for="(item, key) in content.menu"
                  :key="key"
                  :class="{ 'menu-item-has-children': item.subMenu }"
                >
                  <router-link :to="'/demo/'+item.path" @click="hideMobileMenu()">{{ item.name }}</router-link>
                  <ul class="sub-menu" v-if="item.subMenu">
                      <li v-for="(elm, key) in item.subMenu" :key="key">
                        <router-link :to="'/demo/'+elm.path" @click="hideMobileMenu()">{{ elm.name }}</router-link>

                      </li>
                  </ul>
                </li>
              </ul>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: "HeaderView01",
  props: ["content", "cssClass", "startViewLightLayout"],
  methods: {
    hideMobileMenu(){
          $("button.menu-toggle").toggleClass("is-active");
          $("#mobile-panel-menu").toggleClass("active");
    }
  },
  mounted(){
        
        $(".mobile-menu-icon").click(function (e) {
          e.preventDefault();
          $("#header").addClass("scroll");
          $("button.menu-toggle").toggleClass("is-active");
          $("#mobile-panel-menu").toggleClass("active");
        }); 

        $('#mobile-panel-menu .inner .mobile-menu ul > li.menu-item-has-children').on('click', function(e){
          if(e.target !== e.currentTarget) return;
          $(e.currentTarget).find('ul').slideToggle(500);
        });
  }
};
</script>
<style scoped></style>
