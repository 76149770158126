<template>
<div>

    <StartViewMain 
    :layout="layout.startView" 
    :content="pageContent.startView"
    ></StartViewMain>
    <div>
        <PageSectionContainer
        v-for="(item, key) in layout.sections" :key="key"
        :layout="item" 
        :index="key"
        :content="pageContent[item.type]"
        ></PageSectionContainer>
    </div>
</div>
</template>
<script>
import _ from 'lodash';
import StartViewMain from '../layouts/start-view/StartViewMain.vue'
import PageSectionContainer from '../layouts/PageSectionContainer.vue'

export default{
name: 'homePage',
props: ["layout", "content", "path"],
computed: {
    pageContent(){
        return _.filter(this.content.pages, { path: this.path })[0];
    }
},
components: {
    StartViewMain, PageSectionContainer
  }
}
</script>
<style scoped>
</style>