<template>
  <div class="step">
    <p class="field-header">
      {{ this.$store.getters.currentLang.generatorForm.step1.title }}
    </p>
    <p class="sub-header">
      {{ this.$store.getters.currentLang.generatorForm.step1.subTitle }}
    </p>
    <v-autocomplete
      hide-details
      :label="this.$store.getters.currentLang.generatorForm.step1.title"
      :items="industries"
      item-title="industry"
      item-value="industry"
      variant="outlined"
      @update:search="setIndustry($event)"
      v-model="autocompleteIndustry"
      :disabled="customIndustry || disabledStep"
    ></v-autocomplete>
    <v-switch
      v-model="customIndustry"
      :label="this.$store.getters.currentLang.generatorForm.step1.switcherLabel"
      color="primary"
      hide-details
      inset
      :disabled="disabledStep"
    ></v-switch>
    <v-text-field
      v-if="customIndustry"
      :loading="loading"
      append-inner-icon="mdi-magnify"
      :label="this.$store.getters.currentLang.generatorForm.step1.textLabel"
      variant="outlined"
      hide-details
      single-line
      v-model="ownIndustry"
      @click:append-inner="searchIndustryServices"
      @keyup.enter="searchIndustryServices"
      :disabled="disabledStep"
    ></v-text-field>
  </div>
</template>
<script>
import _ from "lodash";
import axiosConfig from '@/axios-config';


export default {
  name: "FormStep1",
  props: ["industries", "disabledStep"],
  data() {
    return {
      ownIndustry: "",
      autocompleteIndustry: null,
      customIndustry: false,
      loaded: false,
      loading: false,
    };
  },
  methods: {
    emitIndustry(payload) {
      this.$emit("setIndustry", payload);
    },
    setIndustry(e) {
      let index = _.findIndex(this.industries, { industry: e });
      if(index >= 0){
        this.emitIndustry(this.industries[index]);
      }
    },
    searchIndustryServices() {
      this.loading = true;
      let industryObj = {
        industry: this.ownIndustry
      };
      let apiPath = "/api/services/"+this.ownIndustry+"/"+this.$store.state.appSettings.lang;
      console.log(apiPath);
      axiosConfig
        .get(apiPath)
        .then(({ data }) => {
          industryObj['services'] = data;
          this.emitIndustry(industryObj);
          this.loading = false;
          this.loaded = true;
        })
        .catch((err) => {
          console.log("Err", err);
            
          this.loading = false;
          this.loaded = true;
        });
    },
  },
};
</script>
<style scoped></style>
